import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Importing all pages
import Blockchain from "./pages/Blockchain";
import Brainstorm from "./pages/Brainstorm";
import Chatpi from "./pages/Chatpi";
import Develop from "./pages/Develop";
import Fireside from "./pages/Fireside";
import Index from "./pages/Index";
import Kyc from "./pages/Kyc";
import Mine from "./pages/Mine";
import Pibrower from "./pages/Pibrower";
import Walletpi from "./pages/Walletpi";
import { initMetaPixel, trackPageView } from "./utils/metaPixel";

export interface AppProps {
  lang: string;
}
function App() {
  useEffect(() => {
    const pixelId = "952811763271292"; // Replace with your Meta Pixel ID
    initMetaPixel(pixelId);
    trackPageView(); // Track page view when the app is loaded
  }, []);

  return (
    <Routes>
      {/* en */}
      <Route path="/en/blockchain" element={<Blockchain lang="en" />} />
      <Route path="/en/brainstorm" element={<Brainstorm lang="en" />} />
      <Route path="/en/chatpi" element={<Chatpi lang="en" />} />
      <Route path="/en/develop" element={<Develop lang="en" />} />
      <Route path="/en/fireside" element={<Fireside lang="en" />} />
      <Route path="/" element={<Index lang="en" />} />
      <Route path="/en" element={<Index lang="en" />} />
      <Route path="/en/kyc" element={<Kyc lang="en" />} />
      <Route path="/en/mine" element={<Mine lang="en" />} />
      <Route path="/en/pibrower" element={<Pibrower lang="en" />} />
      <Route path="/en/walletpi" element={<Walletpi lang="en" />} />

      {/* vi */}
      <Route path="/vi/blockchain" element={<Blockchain lang="vi" />} />
      <Route path="/vi/brainstorm" element={<Brainstorm lang="vi" />} />
      <Route path="/vi/chatpi" element={<Chatpi lang="vi" />} />
      <Route path="/vi/develop" element={<Develop lang="vi" />} />
      <Route path="/vi/fireside" element={<Fireside lang="vi" />} />
      <Route path="/vi" element={<Index lang="vi" />} />
      <Route path="/vi/kyc" element={<Kyc lang="vi" />} />
      <Route path="/vi/mine" element={<Mine lang="vi" />} />
      <Route path="/vi/pibrower" element={<Pibrower lang="vi" />} />
      <Route path="/vi/walletpi" element={<Walletpi lang="vi" />} />
    </Routes>
  );
}

export default App;
